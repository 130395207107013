<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import PageHeader from "@/components/page-header";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";




import axios from 'axios';
import Paginate from 'vuejs-paginate-next';

import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";

export default {

    page: {
        title: "Manage Expenses",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Manage Expenses",
            items: [{
                text: "Manage Expenses",
                href: "/users",
            },
            {
                text: "Manage Expenses",
                active: true,
            },
            ],
            value: ['javascript'],

            date: null,
            isshowexpensetype: false,
            submitted: false,
            regError: null,
            regSuccess: null,
            tryingToRegister: false,
            isRegisterError: false,
            registerSuccess: false,
            userserror: false,
            userserrormsg: '',
            usersok: false,
            is_admin: false,
            usersokmsg: '',
            userSearchTerm: '',
            currentpage: 0,
            totalpage: 0,
            totalrows: 0,
            from_date: '',
            to_date: '',
            total_amount: 0,

            updateuser_id: 0,
            usertype: 'user',
            userstatus: '',
            expenses_list: [],
            expenses_head_list: [],
            payment_holder_name_list: [],
            payment_accounts_list: [],
            passerror: false,
            passok: false,
            passerrormsg: '',
            passokmsg: '',

            type: 'Expense',
            status: 'Active',
            name: '',
            amount: '',
            isadd: true,
            modaltitle: '',
            buttonname: '',
            category_id: -1,
            payment_holder_name_id: '-1',
            payment_account_id: -1,
            order_by: 'addedat',
            sort_by: 'DESC',
            show_rows: 10,

        };
    },
    validations: {

    },
    computed: {

    },
    components: {
        Layout,
        paginate: Paginate,
        PageHeader,
        //Multiselect,
        //flatPickr
    }, mounted: function () {
        this.getUserData();
    },
    methods: {
        clickCallback: function (pageNum) {
            // alert("pageNum==" + pageNum);
            this.currentpage = parseInt(pageNum) - 1;
            this.getUserData();

        }, async filterexpenses() {
            var result = await axios.post(appConfig.api_url + 'expenses/getall_filter', {
                filter: '' + this.userSearchTerm,
                page: this.currentpage,
                from_date: this.from_date,
                to_date: this.to_date,
                expense_head_category_id: this.category_id,
                payment_holder_name_id: this.payment_holder_name_id,
                order_by: this.order_by,
                sort_by: this.sort_by,
                show_rows: this.show_rows,
                payment_account_id: this.payment_account_id
            })

            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;
            this.expenses_list = result.data.data;
            // console.log("-----" + JSON.stringify(this.expenses_list))
            this.expenses_head_list = result.data.expenses_head,
                this.payment_holder_name_list = result.data.payment_holder_name;
            this.total_amount = 0;
            for (var i = 0; i < this.expenses_list.length; i++) {
                this.total_amount = this.total_amount + this.expenses_list[i].amount;
            }
        },
        async getUserData() {
            document.getElementById("flterform").reset();
            this.category_id = -1;
            this.payment_holder_name_id = -1;
            this.order_by = 'addedat';
            this.sort_by = 'DESC';


            var result = await axios.post(appConfig.api_url + 'expenses/getall', {
                filter: '' + this.userSearchTerm,
                page: this.currentpage,
                from_date: this.from_date,
                to_date: this.to_date,
                expense_category_id: this.category_id,
                payment_holder_name_id: this.payment_holder_name_id,
                show_rows: this.show_rows
            })


            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;
            this.expenses_list = result.data.data;
            this.expenses_head_list = result.data.expenses_head,
                this.payment_holder_name_list = result.data.payment_holder_name;
            this.total_amount = 0;
            for (var i = 0; i < this.expenses_list.length; i++) {
                this.total_amount = this.total_amount + this.expenses_list[i].amount;
            }



        },
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        async updateuserprofile() {
            this.submitted = true;
            // stop here if form is invalid

            this.tryingToRegister = true;
            this.regError = null;

            var mode = 'edit';
            if (this.isadd) {
                mode = 'add';
            }

            // alert(appConfig.api_url + '' + goto);

            const result = await axios.post(appConfig.api_url + 'expenses/manage', {
                id: this.updateuser_id,
                mode: mode,
                name: this.name,
                category_id: this.category_id,
                type: this.type,
                amount: this.amount,
                payment_account_id: this.payment_account_id,
                payment_holder_name_id: this.payment_holder_name_id,
                user_id: parseInt(localStorage.getItem('userid'))
            });

            //alert(JSON.stringify(result.data))

            if (result.data.status == 'errors') {
                this.tryingToRegister = false;
                this.regError = result.data.data.data;
                this.isRegisterError = true;
                // return this.authError = result.data.data.data;
            } else {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                this.regSuccess = 'Expenses added';
                this.getUserData();
                document.getElementById('closeupdatemodalbutton').click();
            }


        }, async filteruser() {
            this.expenses_list = [];
            this.getUserData();
        }, updateuser(id, category_id, payment_holder_name_id, type, name, amount, payment_account_id) {
            this.modaltitle = 'Update Expense';
            this.category_id = category_id;
            this.payment_holder_name_id = payment_holder_name_id;
            this.paymentaccounts();
            this.name = name;
            this.buttonname = 'Update';
            this.isadd = false;
            this.updateuser_id = id;
            this.type = type;
            this.amount = amount;
            this.payment_account_id = payment_account_id;
            document.getElementById('openupdatemodalbutton').click();
            this.isRegisterError = false;
            this.registerSuccess = false;

        }, createuser() {
            this.isRegisterError = false;
            this.registerSuccess = false;
            this.modaltitle = 'Create new expense';
            this.buttonname = 'Add';
            this.isadd = true;
            this.payment_holder_name_id = -1;
            this.updateuser_id = -1;
            this.type = 'Expense';
            this.name = '';
            this.amount = 0;
            this.payment_account_id = -1;
            this.category_id = -1;
            document.getElementById('openupdatemodalbutton').click();
        }, async paymentaccounts() {
            // alert(this.payment_holder_name_id);
            var result = await axios.post(appConfig.api_url + 'project/get_single_payment_accounts', {
                payment_holder_name_id: this.payment_holder_name_id
            })
            // alert(result.data);
            this.payment_accounts_list = result.data.data;
        }, async deleteexpense(expense_id) {
            //  alert(expense_id);
            let isExecuted = confirm("Are you sure to delete expense?");

            if (isExecuted) {
                await axios.post(appConfig.api_url + 'expenses/manage', {
                    id: expense_id,
                    mode: 'delete'
                })
                this.getUserData();
                this.registerSuccess = true;
                this.regSuccess = 'Expenses Deleted';

            }

        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <form @submit.prevent="filterexpenses" id="flterform">
            <div class="row p-3 mb-2" style="background-color: white">
                <!-- <h3 class="m-2">Users</h3> -->
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                    <div class="form-icon" style="width:11%;    padding-top: 14px;">
                        <!-- <la    bel for="firstName" class="form-label">Search</label> -->
                        <input v-model="userSearchTerm" type="text" placeholder="Search" class="form-control"
                            aria-label="Recipient's username" aria-describedby="button-addon2">
                        <!-- <i class=" ri-search-line"></i> -->
                    </div>

                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">From</label>
                            <input type="date" v-model="from_date" class="form-control mb-3" />
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div>
                            <label for="firstName" class="form-label">To</label>
                            <input type="date" v-model="to_date" class="form-control mb-3" />
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Exp. Head</label>
                            <select v-model="category_id" class="form-select mb-3" aria-label="Default select example">
                                <option value="-1">All</option>
                                <option v-for="(user) of expenses_head_list" v-bind:key="user.id" :value="user.id">
                                    {{ user.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Exp. By</label>
                            <select v-model="payment_holder_name_id" @change="paymentaccounts" class="form-select mb-3"
                                aria-label="Default select example">
                                <option value="-1">All</option>
                                <option v-for="(user) of payment_holder_name_list" v-bind:key="user.id"
                                    :value="user.id">{{ user.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <label for="firstName" class="form-label">By Acc.</label>
                        <select required v-model="payment_account_id" class="form-select mb-3"
                            aria-label="Default select example">
                            <option value="-1">All</option>
                            <option v-for="(profile) of payment_accounts_list" v-bind:key="profile.id"
                                :value="profile.id">{{ profile.type }} ({{
                                    profile.details
                                }})
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Order</label>
                            <select v-model="order_by" class="form-select mb-3" aria-label="Default select example">

                                <option value="addedat">Date</option>
                                <option value="amount">Amount</option>
                                <option value="id">Expense ID</option>
                                <option value="name">Details</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div>
                            <label for="firstName" class="form-label">Sort</label>
                            <select v-model="sort_by" class="form-select mb-3" aria-label="Default select example">
                                <option value="DESC">Desending</option>
                                <option value="ASC">Ascending</option>


                            </select>
                        </div>
                    </div>

                    <div class="col-lg-1">
                        <button type="submit" class="btn btn-primary">Filter</button>
                        <button type="button" style="margin-top:5px" @click="getUserData()"
                            class="btn btn-primary">Refresh</button>
                    </div>

                    <div class="col-lg-1 text-left">
                        <button class="btn btn-success " style="float:right" @click="createuser">+ </button><br><br>
                        <router-link :to="'/report/plreport/' + from_date + '/' + to_date" class="nav-link">
                            PL Report
                        </router-link>
                    </div>


                </div>
            </div>
        </form>

        <form class="needs-validation" @submit.prevent="updateuserprofile" enctype="multipart/form-data">
            <div class="row">
                <div class="card ">


                    <div class="card-body m-2">
                        <div class="row col-lg-12">
                            <b-alert v-model="registerSuccess" class="mb-3" style="padding-bottom:10px"
                                variant="success" dismissible>
                                {{ regSuccess }}
                            </b-alert>
                            <br>
                        </div>

                        <div class="table-responsive table-card">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">Action</th>

                                        <th scope="col">ID</th>
                                        <th scope="col">Expense Head</th>
                                        <th scope="col">Expense by Person</th>
                                        <th scope="col">Expense from account</th>
                                        <th scope="col">Details</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Created by</th>
                                        <th scope="col">Created at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user) of expenses_list" v-bind:key="user.id"
                                        :style="user.type == 'Expenses' ? 'background-color:#ddfcdd' : 'background-color:#ffe5e5'">
                                        <td>
                                            <a
                                                @click="updateuser(user.id, user.category_id, user.payment_holder_name_id, user.type, user.name, user.amount, user.payment_account_id)"><i
                                                    class="ri-edit-2-fill"></i></a>
                                            <a @click="deleteexpense(user.id)"><i
                                                    class="ml-2 ri-delete-bin-2-fill"></i></a>
                                        </td>
                                        <td>{{ user.id }}</td>
                                        <td>{{ user.Expenses_Head }}</td>
                                        <td>{{ user.Expense_by_person_name }}</td>
                                        <td>{{ user.payment_acount_type }} of {{ user.payment_account_details }}</td>
                                        <td>{{ user.name }}</td>
                                        <td>Rs. {{ user.amount }}</td>
                                        <td>{{ user.addedbyname }}</td>
                                        <td>{{ user.custome_addedat }}</td>


                                    </tr>

                                </tbody>

                                <!-- Table Foot -->
                                <tfoot class="table-light">
                                    <tr>
                                        <td colspan="6">Total Amount</td>
                                        <td>Rs. {{ total_amount }}</td>
                                        <td colspan="2"></td>
                                    </tr>
                                </tfoot>

                            </table>
                            <!-- end table -->
                        </div>

                        <div class="mt-4">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                                    <p style="float:left;"><span style="font-weight:bold">Total Rows: {{
                                        totalrows
                                    }}</span> Show Rows:<input type="number" v-model="show_rows"
                                            style="width:10%" /> </p>

                                    <div class="page-title-right" style="float:right">
                                        <!-- <span style="font-weight:bold;">Total Amount: {{ total_amount }}</span> -->
                                        <paginate v-model="page" :page-count="totalpage" :margin-pages="2"
                                            :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'"
                                            :container-class="'pagination'" :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!--end tab-pane-->


                    </div>
                </div>
                <!--end col-->
            </div>
        </form>
        <div class="row">

        </div>

        <!-- start of create document edit modal -->
        <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
        <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
                        <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row col-lg-12">
                            <b-alert v-model="isRegisterError" class="mt-3 mb-3" variant="danger" dismissible>{{
                                regError
                            }}
                            </b-alert>
                        </div>
                        <form @submit.prevent="updateuserprofile" id="updateDocumentform">
                            <div class="row g-3">
                                <div class="col-lg-4">
                                    <div>
                                        <label for="firstName" class="form-label">Expense Head</label>
                                        <select required v-model="category_id" class="form-select mb-3"
                                            aria-label="Default select example">
                                            <option v-for="(user) of expenses_head_list" v-bind:key="user.id"
                                                :value="user.id">{{ user.name }}</option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div>
                                        <label for="firstName" class="form-label">Expense By</label>
                                        <select required v-model="payment_holder_name_id" @change="paymentaccounts"
                                            class="form-select mb-3" aria-label="Default select example">
                                            <option v-for="(user) of payment_holder_name_list" v-bind:key="user.id"
                                                :value="user.id">{{ user.name }}</option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <label for="firstName" class="form-label">Expense at Account</label>
                                    <select required v-model="payment_account_id" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option v-for="(profile) of payment_accounts_list" v-bind:key="profile.id"
                                            :value="profile.id">{{ profile.type }} ({{
                                                profile.details
                                            }})
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-4" v-if="isshowexpensetype">
                                    <label for="firstName" class="form-label">Select Type</label>
                                    <select v-model="type" class="form-select mb-3" aria-label="Default select example">
                                        <option selected="selected" value="Expense">Expense</option>
                                        <option value="Income">Income</option>
                                    </select>
                                </div>
                                <!--end col-->

                                <div class="col-lg-4">
                                    <label for=" emailInput" class="form-label">Details</label>
                                    <input type="text" class="form-control" v-model="name" />
                                </div>
                                <div class="col-lg-4">
                                    <label for=" emailInput" class="form-label">Amount</label>
                                    <input type="number" class="form-control" v-model="amount" />
                                </div>

                                <!--end col-->

                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light"
                                            data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">{{ buttonname }}</button>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!--end row-->
    </Layout>
</template>